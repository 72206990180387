import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import FloatingMenu from '../components/FloatingMenu';
import p1p5 from '../images/p1p5.jpg';
import p1p6 from '../images/p1p6.jpg';
import Footer from '../components/Footer';

const Page1 = () => {
   
    return (
        <>
            <div>
                <NavBar />
            </div>

            <div className="pt-16"> 
                <section className="bg-white dark:bg-white-800 dark:text-black-100"> {/* Change the background to white */}
                    <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl dark:text-blue-900">Welcome Employers!</h2>
			<p className="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-black-400"></p>
		</div>
		<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50">Discover unparalleled staffing solutions with L&E</h3>
				<p className="mt-3 text-lg dark:text-blue-900 font-bold">Elevate Your Team with Tailored Recruitment Solutions </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Customized Staffing Solutions</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">We provide tailored staffing solutions that cater to your unique workforce needs and business objectives.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Executive Search</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Our executive search services focus on identifying and securing high-caliber leadership talent to drive your company forward.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Temporary and Permanent Staffing</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Whether you need temporary assistance or permanent team members, we have you covered</p>
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={p1p5} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
			</div>
		</div>
		<div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50 font-bold">Elevate Your Success with Expert Talent Acquisition</h3>
					<p className="mt-3 text-lg dark:text-blue-900 font-bold">Unlock success with our tailored talent solutions, shaped by a deep understanding of your company's unique needs</p>
					<div className="mt-12 space-y-12">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Extensive Talent Pool</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">Our vast network of qualified candidates empowers us to find the perfect match for your organization.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Client-Centric Approach</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">We put our clients first and work closely with you to fulfill your unique staffing needs.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Commitment to Excellence</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">We are committed to maintaining the highest standards of professionalism and ethics in all our interactions.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src={p1p6} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
</section>
          </div>
		  <Footer />
		  <FloatingMenu />
        </>


    )
}

export default Page1;