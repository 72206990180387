import React, { useState } from 'react';
import img1 from '../images/imgp5.png';
import img2 from '../images/imgp6.png';


const S3 = () => {
   
    return (
        <>
            <div className="pt-16"> 
                <section className="bg-white dark:bg-white-800 dark:text-black-100"> 
                    <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                        <div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50 font-bold">Dynamic Tech Solutions</h3>
					<p className="mt-3 text-lg dark:text-blue-900 font-bold">Unlock innovation and growth with our skilled professionals and tailor-made software consulting services.</p>
					<div className="mt-12 space-y-12">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Global Reach</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">Direct placement, offshore support, and contract deployment for flexible project delivery.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Industry Impact</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">Empowering enterprise, SaaS vendors, private equity, and non-software firms with data-driven technology enhancements.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Innovative Solutions</h4>
								<p className="mt-2 dark:text-blue-900 font-bold"> Driving technology transformations with a seasoned team skilled in MICROSOFT TECHNOLOGIES, DATA ANALYTICS, MACHINE LEARNING, and more.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src={img2} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
</section>
          </div>
        </>


    )
}

export default S3;