import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./FloatingMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isOpen]);

  const handleMenuClick = (e) => {
    // Prevent the click event from propagating to the document click listener
    e.stopPropagation();
  };

  return (
    <div className={`floating-menu ${isOpen ? "open" : ""}`} onClick={handleMenuClick}>
      <button className="menu-button" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBriefcase} size="2x" />
      </button>
      <div className="menu-options">
        <Link to="/employer/page1" className="menu-option">
          Employer
        </Link>
        <Link to="/employer/page2" className="menu-option">
          Job-Seekers
        </Link>
        
      </div>
    </div>
  );
};

export default FloatingMenu;
