import React, { useState } from 'react';
import img1 from '../images/imgp5.png';
import img2 from '../images/imgp6.png';


const S2 = () => {
   
    return (
        <>
            <div className="pt-16"> 
                <section className="bg-white dark:bg-white-800 dark:text-black-100"> 
                    <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl dark:text-blue-900">SOFTWARE CONSULTING Services</h2>
			<p className="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-black-400"></p>
		</div>
		<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50">LION & ELEPHANTS</h3>
				<p className="mt-3 text-lg dark:text-blue-900 font-bold">Our talent pool of cutting-edge technology experts drives transformative solutions for our clients. </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Strategic Software Services</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Tailored solutions for tech enhancements, talent staffing, and transformative strategy across industries.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Technology Experts</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Cutting-edge professionals driving innovative solutions in diverse technology stacks.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Versatile Talent</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Proficient in JAVA, ANGULAR, PYTHON, SALESFORCE, AWS / AZURE, and more.</p>
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={img1} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
			</div>
		</div>
		
	</div>
</section>
          </div>
        </>


    )
}

export default S2;