import React from 'react';

import S1 from '../components/S1';
import S2 from '../components/S2';
import S3 from '../components/S3';
import S4 from '../components/S4';
import FloatingMenu from '../components/FloatingMenu';

const Solutions = () => {
    return (
        <>
            <S1 />
            <S2 />
            <S3 />
            <S4 />
            <FloatingMenu />
        </>

    )
}

export default Solutions;

