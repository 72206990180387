import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import NavBar from '../components/Navbar/NavBar';
import img1 from '../images/imgp13.png';
import img2 from '../images/imgp14.png';
import img3 from '../images/imgp15.png';
import img4 from '../images/imgp16.png';

const S1 = () => {
  return (
    <>
      <div className="relative hero" id="hero">
        <div>
          <NavBar />
        </div>
        </div>
        <section class="bg-gray-900 mt-16 text-white">
  <div
    class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center"
  >
    <div class="mx-auto max-w-3xl text-center">
      <h1
        class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
      >
        Unleash Your Software Potential 

        <span class="sm:block"> Optimize and Innovate </span>
      </h1>

      <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
      Elevate your software potential with our tailored consulting services spanning M&A, product strategy, change management, and more, for all software company types.
      </p>

      <div className="flex flex-col items-center justify-center mt-12">
	<div className="flex space-x-8">
		<img alt="" className="w-16 h-16 rounded-full ri ri dark:bg-gray-500 ri ri" src={img1} />
		<img alt="" className="w-16 h-16 rounded-full ri ri dark:bg-gray-500 ri ri" src={img2} />
		<img alt="" className="w-16 h-16 rounded-full ri ri dark:bg-gray-500 ri ri" src={img3} />
		<img alt="" className="w-16 h-16 rounded-full ri ri dark:bg-gray-500 ri ri" src={img4} />
	</div>
</div>
    </div>
  </div>
</section>
    </>
  );
};

export default S1;
