import React from 'react';
import img1 from '../images/imgp7.png';
import img2 from '../images/imgp8.png';
import img3 from '../images/imgp9.png';
import img4 from '../images/imgp10.png';
import img5 from '../images/imgp11.png';
import img6 from '../images/imgp12.png';
import './S4.css';
const S4 = () => {
  return (
    <>
    <div className='mt-16'>
        <div>
          <h2 className="text-3xl font-bold tracki text-center sm:text-5xl dark:text-blue-900">SOFTWARE SOLUTIONS!</h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-black-400"></p>
        </div>
        <div className='responsive-grid ml-2 mr-2'>
      
      <div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img1} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Software Development</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">We develop and deliver software applications as a service and  we create custom software solutions tailored to your needs, leveraging cutting-edge technologies to deliver reliable and scalable applications that drive your business forward.</p>

    
</div>
        
          
      </div>

      <div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img2} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Software Architecture Assessment & Redesign</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">We evaluate and enhance your existing software architecture, ensuring it aligns with industry best practices for improved performance, security, and future scalability.</p>

    
</div>
</div>

<div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img3} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Software Modernization</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">We transform legacy systems into modern, efficient software, preserving valuable data and functionality while integrating new features and technologies for a competitive edge.</p>

    
</div>
</div>


<div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img4} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Software Stack Assessment</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">We help you choose the optimal technology stack for your project, ensuring the right tools and frameworks to achieve your goals efficiently and effectively.</p>

    
</div>
</div>


<div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img5} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Team Augmentation</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">Extend your team with our skilled professionals to accelerate development, fill expertise gaps, and deliver high-quality results on time.</p>

    
</div>
</div>


<div className="w-full max-w-sm p-4  dark:border-gray-700">
      <div class="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-lg shadow-lg dark:bg-gray-800">
    <div class="flex justify-center -mt-16 md:justify-end">
        <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full dark:border-blue-400" alt="Testimonial avatar" src={img6} />
    </div>

    <h2 class="mt-2 text-xl font-semibold text-gray-800 dark:text-white md:mt-0">Business Process Analysis</h2>

    <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">We analyze your business processes, identify bottlenecks, and recommend solutions to streamline operations, enhance productivity, and drive cost savings.</p>

    
</div>
</div>



    </div>
    </div>
    </>
  );
};

export default S4;
