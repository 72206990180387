import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/#about">
        About
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/#services">
        Services
      </HashLink>

      {/* <div className="relative inline-block text-left">
        <div
          onClick={toggleDropdown}
          className="cursor-pointer px-4 font-extrabold text-gray-900 hover:text-blue-900"
        >
          Employer
          <span className="ml-1 text-xs">▼</span>
        </div>
        {isDropdownOpen && (
          <div
            className="absolute z-10 w-40 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-lg right-0"
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <HashLink
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-900 font-bold"
                smooth
                to="/employer/page1"
              > 
                Employer
              </HashLink>
              <HashLink
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-900 font-bold"
                smooth
                to="/employer/page2"
              >
                Job-Seekers
              </HashLink>
              <HashLink
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-900 font-bold"
                smooth
                to="/employer/page3"
              >
                Executive Search
              </HashLink>
            </div>
          </div>
        )}
      </div> */}
      
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/Solutions">
        Solutions
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/employer/page2">
        Job-Seekers
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/employer/page1">
        Employers
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/employer/page3">
        Executive Search
      </HashLink>
      {/* <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/Career">
        Career
      </HashLink> */}
      <HashLink className="px-4 font-extrabold text-gray-900 hover:text-blue-900" smooth to="/Specialization">
        Specialization
      </HashLink>
      <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/contact">
        Contact Us
      </HashLink>
    </>
  );
};

export default NavLinks;
