import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks'; 
import logo from './logo.png'; 

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleMenuToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out bg-white ${isScrolled ? 'border-b border-gray-300 shadow-lg' : ''}`}>
      <div className="flex items-center justify-between py-2 px-6 md:py-4 md:px-10">
        <div className="flex items-center justify-start">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="logo"
              style={{ width: '160px', height: '64px' }}
            />
          </Link>
        </div>
        <div className={`hidden lg:flex lg:flex-row mt-4 lg:items-right lg:space-x-6 ${isOpen ? 'hidden' : 'flex'}`}>
          <div className="lg:ml-auto">
            <NavLinks /> 
          </div>
        </div>
        <div className="lg:hidden">
          <button
            className={`p-2 rounded-lg text-blue-900 ${isOpen ? 'bg-blue-200' : ''}`}
            onClick={handleMenuToggle}
          >
            <svg
              className={`h-6 w-6 fill-current ${isOpen ? 'text-blue-700' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.293 19.293a1 1 0 0 1-1.414 0L12 13.414 5.121 20.293a1 1 0 0 1-1.414-1.414L10.586 12 3.707 5.121a1 1 0 0 1 1.414-1.414L12 10.586l6.879-6.879a1 1 0 0 1 1.414 1.414L13.414 12l6.879 6.879a1 1 0 0 1 0 1.414z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile menu overlay */}
      {isOpen && (
        <div className="lg:hidden bg-white fixed top-0 right-0 w-64 h-full z-40">
          <div className="flex items-center justify-end p-4">
            <button
              className="p-2 rounded-lg text-blue-900"
              onClick={handleMenuToggle}
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.293 19.293a1 1 0 0 1-1.414 0L12 13.414 5.121 20.293a1 1 0 0 1-1.414-1.414L10.586 12 3.707 5.121a1 1 0 0 1 1.414-1.414L12 10.586l6.879-6.879a1 1 0 0 1 1.414 1.414L13.414 12l6.879 6.879a1 1 0 0 1 0 1.414z"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col items-start p-5">
            <NavLinks /> 
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
