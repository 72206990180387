import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Page3p1 from './Page3p1';
import Page3p2 from './Page3p2';
import img1 from '../images/imgp1.png';
import { Link } from 'react-router-dom';
import FloatingMenu from '../components/FloatingMenu';
import img2 from '../images/imgp2.png';
import img3 from '../images/imgp4.png';
import img4 from '../images/imgp3.png';
import Footer from '../components/Footer';

const Page3 = () => {
   
    return (
        <>
            <div>
                <NavBar />
            </div>

            <div className="relative bg-gray-900 mt-16">
      <div className="absolute inset-x-0 bottom-0">
        <svg
          viewBox="0 0 224 12"
          fill="currentColor"
          className="w-full -mb-1 text-white"
          preserveAspectRatio="none"
        >
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
        </svg>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
          <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
          Welcome to  
            <br className="hidden md:block " />
            LION & ELEPHANTS{' '}
            <span className="relative inline-block">
            Consultancy Pvt Ltd{' '}
              <div className="w-full h-3 -mt-3 bg-deep-purple-accent-400" />
            </span>
          </h2>
          <p className="mb-6 text-base  tracking-wide text-white md:text-lg font-bold">
          We specialize in finding exceptional executive talent that propels businesses to success.We take pride in our commitment to delivering tailored executive search solutions that match unique business needs and company cultures.
          </p>
          
          <p className="max-w-md mb-10 text-xs font-bold tracking-wide text-white sm:text-sm sm:mx-auto md:mb-16">
          Premium Lounge - Executive Search
          </p>
          
          <div className="w-16 h-16 flex justify-center items-center border-4 border-dashed rounded-full animate-spin dark:border-violet-400 mx-auto"></div>
        </div>
      </div>
      
    </div>
    <Page3p1 />
    <Page3p2 />
    <div className="w-full flex items-center justify-center text-white cta">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                        <p className='text-2xl md:text-4xl font-bold mb-4'> We strive to identify and attract exceptional executive talent for our clients</p>
                        <p className="text-lg md:text-2xl">The final hiring decision rests with the client organization. Our services do not guarantee specific outcomes,  <span className='font-black'>but we are committed to delivering the highest standards of professionalism and excellence in executive recruitment.!</span></p>
                    </div>
                    
                    <div className="w-full lg:w-72 pt-6 lg:mx-12">
                        <Link to="/contact" className="bg-transparent border hover:bg-blue-900 hover:border-blue-800 text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group">Send a message
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    <section class="ml-16">
  <div class="flex flex-wrap mx-auto md:flex-nowrap p-12">

    
      <div class="flex w-full">
        <div class="relative flex flex-col items-start m-1 transition duration-300 ease-in-out delay-150 transform bg-white shadow-2xl rounded-xl md:w-80 md:-ml-16 md:hover:-translate-x-16 md:hover:-translate-y-8" >
          <img class="object-cover object-center w-full rounded-t-xl lg:h-48 md:h-36" src={img2} alt="blog" />
          <div class="px-6 py-8">
            <h4 class="mt-4 text-2xl font-semibold text-neutral-600">
              <span class="">Market Insights</span>
            </h4>
            <p class="mt-4 text-base font-normal text-gray-500 leading-relax">Stay ahead of the competition with our in-depth market intelligence. We conduct thorough research and analysis to deliver valuable market insights.</p>
          </div>
        </div>
      </div>
    

   
      <div class="flex w-full">
        <div class="relative flex flex-col items-start m-1 transition duration-300 ease-in-out delay-150 transform bg-white shadow-2xl rounded-xl md:w-80 md:-ml-16 md:hover:-translate-x-16 md:hover:-translate-y-8">
          <img class="object-cover object-center w-full rounded-t-xl lg:h-48 md:h-36" src={img1} alt="blog" />
          <div class="px-6 py-8">
            <h4 class="mt-4 text-2xl font-semibold text-neutral-600">
              <span class="">Global Reach</span>
            </h4>
            <p class="mt-4 text-base font-normal text-gray-500 leading-relax">Our executive search capabilities extend far and wide, reaching exceptional candidates on a global scale.We are equipped to meet your executive talent needs anywhere in the world.</p>
          </div>
        </div>
      </div>
    
      <div class="flex w-full">
        <div class="relative flex flex-col items-start m-1 transition duration-300 ease-in-out delay-150 transform bg-white shadow-2xl rounded-xl md:w-80 md:-ml-16 md:hover:-translate-x-16 md:hover:-translate-y-8">
          <img class="object-cover object-center w-full rounded-t-xl lg:h-48 md:h-36" src={img4} alt="blog" />
          <div class="px-6 py-8">
            <h4 class="mt-4 text-2xl font-semibold text-neutral-600">
              <span class="">Unparalleled Expertise</span>
            </h4>
            <p class="mt-4 text-base font-normal text-gray-500 leading-relax">Our team of experienced consultants possesses an in-depth understanding of various industries, ensuring a tailored and effective executive search approach.</p>
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <div class="relative flex flex-col items-start m-1 transition duration-300 ease-in-out delay-150 transform bg-white shadow-2xl rounded-xl md:w-80 md:-ml-16 md:hover:-translate-x-16 md:hover:-translate-y-8">
          <img class="object-cover object-center w-full rounded-t-xl lg:h-48 md:h-36" src={img3} alt="blog" />
          <div class="px-6 py-8">
            <h4 class="mt-4 text-2xl font-semibold text-neutral-600">
              <span class="">Confidentiality and Discretion</span>
            </h4>
            <p class="mt-4 text-base font-normal text-gray-500 leading-relax">We value the sensitivity of executive search and handle all interactions with the utmost confidentiality and discretion.</p>
          </div>
        </div>
      </div>
    

  </div>
</section>
<div class="max-w-lg mx-auto">
    {/* <div class="flex bg-blue-100 rounded-lg p-4 mb-4 text-sm text-blue-700" role="alert">
        <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <div>
            <span class="font-medium">Info alert!</span> Change a few things up and try submitting again.
        </div>
    </div>
    <div class="flex bg-red-100 rounded-lg p-4 mb-4 text-sm text-red-700" role="alert">
        <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <div>
            <span class="font-medium">Danger alert!</span> Change a few things up and try submitting again.
        </div>
    </div>
    <div class="flex bg-green-100 rounded-lg p-4 mb-4 text-sm text-green-700" role="alert">
        <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <div>
            <span class="font-medium">Success alert!</span> Change a few things up and try submitting again.
        </div>
    </div> */}
    <div class="flex bg-yellow-100 rounded-lg p-4 mb-4 text-sm text-yellow-700" role="alert">
        <svg class="w-20 h-20 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <div>
            <span class="font-medium">Disclaimer:</span> LION & ELEPHANTS is an executive search and leadership advisory firm. We strive to identify and attract exceptional executive talent for our clients; however, the final hiring decision rests with the client organization. 
        
 </div>
    </div>
</div>
<FloatingMenu />
<Footer />
      </>


    )
}

export default Page3;