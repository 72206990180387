import React from 'react';
import img from '../images/imgp1.png';
import { Link } from 'react-router-dom';
import FloatingMenu from '../components/FloatingMenu';
const Page3p1 = () => {
    return (
        
        <>
        
                <div className="m-auto mt-12 max-w-6xl p-2 md:p-12 h-5/6" id='Executive' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    
                                </div>
                            </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-blue-900 font-bold">About Us</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>With years of experience in the industry, LION & ELEPHANTS has built a reputation for excellence in executive recruitment.
          </p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Our team of seasoned professionals brings a deep understanding of diverse industries and functional domains, enabling us to identify and attract <br/>top-tier executive candidates.</p>
                            </div>
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Send a message
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <FloatingMenu />
                
        </>
    )
}

export default Page3p1;