import React, { useEffect, useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';

import FloatingMenu from '../components/FloatingMenu';
import NavBar from '../components/Navbar/NavBar';
import img from '../images/p1p6.jpg';
import img2 from '../images/Web-developer.png';
import { Link } from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';
import './Home.css'
import img3 from '../images/app.png'; 
import img4 from '../images/consultation.png';
import img5 from '../images/hosting.png';
import img6 from '../images/web.png'; 
import img7 from '../images/team.png'; 
import img8 from '../images/Picture1.png';
import img9 from '../images/Picture2.png';
import img10 from '../images/Picture3.png';
import img11 from '../images/serv1.png';
import img12 from '../images/serv2.png';
import img13 from '../images/undraw_circuit_sdmr.png';
import img14 from '../images/undraw_Connection_re_lcud.png';

const Home = () => {
    useEffect(() => {
        AOS.init({
          duration: 800,
          offset: 100,
          once: true,
          
        });
      }, []);

      const slides = [
        {
          url: img8,
        text: 'LION & ELEPHANTS provides best in breed options in the Industry'
        },
        {
          url: img9,
          text: 'Catch the pace of life and in the corporate life'
        },
        {
          url: img10,
            text:'LION & ELEPHANTS',
            text: 'Niche, seasoned, professionally equipped - experts on your side'

    },
    
        
        
      ];  
      
      const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  
    return (
        <div>
            <div className="relative hero" id="hero">
        <div>
          <NavBar />
        </div>
        <br />
        <br />
        <div className='max-w-[1600px] h-[580px] w-full m-auto py-12 px-0 relative group'>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className='w-full h-full  bg-center bg-cover duration-500'
      >
        <div className="w-full h-full bg-center bg-cover duration-500 relative">
  <img src={slides[currentIndex].url} alt="Carousel Image" className="w-full h-full" />
  <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-4 text-center">
    <p className="text-white text-3xl font-bold">
      {slides[currentIndex].text}</p>
        </div>
        </div>
      </div>
      {/* Left Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex top-4 justify-center py-2'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className= {`text-2xl cursor-pointer ${slideIndex === currentIndex ? 'text-blue-500' : ''}`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );

  <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Who we are</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900"> Ensuring Comprehensive Solutions</h2>
                    </div>

                

                    <p class="mb-3 text-gray-500 dark:text-gray-400"><h1 className="md:text-xl text-xl font-bold text-blue-900">LION & ELEPHANTS </h1>we are committed to be a disruptive and innovative company to assist the professionals to find their next best move in their career journey and provide enterprises with the right fit to embrace their business to grow and support.</p><br/>
<div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
    <p class="mb-3 text-gray-500 dark:text-gray-400"><h1 className=" md:text-xl text-xl font-bold text-blue-900">
    LION & ELEPHANTS
              </h1>committed to serve the industries skill pool by finding the right strength to meet the growing man power demand of the next century.</p>
    <p class="mb-3 md:text-xl text-xl font-bold text-blue-900">Bringing the millennials and Gen Next’s diversified outlook to the new world of opportunities</p>
    <p class="mb-3 text-gray-500 dark:text-gray-400"><h1 className=" md:text-xl text-xl font-bold text-blue-900">
    LION & ELEPHANTS
              </h1>would find the right candidates by employing the industry experts in sourcing to selection before presenting the candidates to the Client/Company/Enterprise</p>
</div>
<br/>
<p class="mb-3 text-gray-500 dark:text-gray-400"><h1 className="md:text-xl text-xl font-bold text-blue-900">
                We Understand What you Need
              </h1>Enterprises / Companies will be provided with best class industry standards screened professional candidates, saving a lot of time and efforts for the client and with utmost quality – a perfect fit on time at ease</p>


            </section>
        </div>


        <div
          className="m-auto overflow-hidden mx-4 mt-4 lg:mt-4 p-2 md:p-12 h-5/6 "
          data-aos="zoom-in"
        >
          <div
            className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left "
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="lg:w-1/2 flex flex-col justify-center">
              <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                We Understand What you Need
              </h1>
              <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                Empowering professionals and enterprises to thrive through strategic career moves
                and business growth.
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-13">
                <HashLink className="inline-flex s-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0" smooth to="/#whyus"
                >
                  Learn More
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    ></path>
                  </svg>
                </HashLink>
              </div>
            </div>
            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
          </div>
        </div>
      </div>
        
      <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                    </div>

                    <div className="px-24" data-aos="fade-down" data-aos-delay="600">
                    <div class="px-2 sm:px-24">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Talent Acquisition Partnership</h2>
                                    <p className="text-md font-medium">
                                    Collaborate with enterprise HR teams to streamline sourcing, screening, and interview processes, ensuring seamless candidate fulfillment while leveraging their domain expertise for effective talent acquisition. Enhance synergy between HR and recruitment efforts, optimizing overall hiring outcomes.
                                   </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img7} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Recruitment Solution</h2>
                                    <p className="text-md font-medium">
                                    Plan & execute a recruitment drive, sourcing top candidates through targeted channels and streamlining the selection process for optimal results. Strengthen employer branding to attract top talent and continuously measure and refine the strategy for future hiring needs.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img5} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Placement Services</h2>
                                    <p className="text-md font-medium">
                                    We specialize in delivering candidates that precisely match your specific job requirements and criteria.
Our approach focuses on providing a tailored and precise candidate selection process.With a focus on your needs, we ensure the right candidates are identified and offered for fulfillment.
                                    </p>
                                </div>
                            </div>

                            

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img11} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Consulting</h2>
                                    <p className="text-md font-medium">
                                   Acquire dedicated expertise in specific skills or technologies through contracted resources, enhancing project efficiency and proficiency. Access specialized talent on a temporary basis, optimizing resource utilization and project outcomes effectively.</p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img12} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Consulting & Service delivery / Offshoring</h2>
                                    <p className="text-md font-medium">
                                   End-to-end project management ensuring comprehensive solution execution and successful delivery of projects from inception to implementation.</p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img6} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Program / Initiative Level</h2>
                                    <p className="text-md font-medium">
                                    Our Hiring Bundle offers end-to-end recruitment services, tailoring the team composition to match specific program, initiative, or project requirements. From sourcing to onboarding, we deliver a customized workforce that aligns perfectly with your project's needs, ensuring success and efficiency.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                      </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">We <span className='font-black'>Build</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                With a passionate team of skilled professionals, we construct innovative solutions that cater to the unique needs of our clients. Our collaborative approach fosters strong partnerships, ensuring we deliver results that exceed expectations. Whether it's developing cutting-edge software, creating captivating designs, or building robust infrastructures, we take pride in constructing a better future for businesses and individuals alike.</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">We <span className='font-black'>Collaborate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Flexible Staffing Solutions for Diverse Business Needs. We offer Direct placement of our talents at our clients' locations.We also support offshore project delivery, off the premises of clients' locations Bringing the millennials and Gen Nexts diversified outlook to the new world of opportunities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
</div>

<div id="whyus" className="bg-gray-100 py-12" >
<div className="pt-16"> 
                <section className="bg-white dark:bg-white-800 dark:text-black-100"> {/* Change the background to white */}
                    <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl dark:text-blue-900">Why Choose LION & ELEPHANTS!</h2>
			<p className="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-black-400"></p>
		</div>
		<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50">Unmatched Accessibility and Efficiency in Talent Acquisition</h3>
				<p className="mt-3 text-lg dark:text-blue-900 font-bold">Streamlined and simplified, ensuring quick and efficient interactions.</p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Extensive Network</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Our platform connects thousands of job seekers and employers, giving you access to a vast talent pool or job opportunities.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">User-Friendly Interface</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Our intuitive interface ensures a seamless experience for both job seekers and employers, making the recruitment process quick and straightforward.</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Advanced Search Filters</h4>
							<p className="mt-2 dark:text-blue-900 font-bold">Job seekers can narrow down their search using various filters, while employers can easily find the ideal candidates.</p>
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={img14} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
			</div>
		</div>
		<div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-black-50 font-bold">Empowering Connections Through Innovative Recruitment Solutions</h3>
					<p className="mt-3 text-lg dark:text-blue-900 font-bold">Ultimate destination for forging meaningful and fruitful connections in the job market.</p>
					<div className="mt-12 space-y-12">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Applicant Tracking System</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">Employers can efficiently manage applications, track candidate progress, and communicate with potential hires.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Personalized Job Alerts</h4>
								<p className="mt-2 dark:text-blue-900 font-bold"> Job seekers can stay informed about new opportunities that match their interests and skills.</p>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi dark:text-black-50 font-bold">Expert support</h4>
								<p className="mt-2 dark:text-blue-900 font-bold">Our team of recruitment experts is always ready to assist job seekers and employers to make the right connections.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src={img13} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
</section>
          </div>      

            
</div>






            
            

        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">What we offer our clients</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Revolutionizing Career Connections</h2>
                    </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                    <div  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                           <p>At LION & ELEPHANTS, we are dedicated to connecting talented individuals with exceptional career opportunities and helping businesses find the perfect fit for their staffing needs.</p>                             
                        </div>
                        

                        <div  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                           <p>With a proven track record of success, we offer comprehensive placement services and innovative solutions to ensure a seamless and rewarding recruitment experience.</p>                             
                        </div> 

                        <div  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <p>Other than - layoffs, reduction in workforce, change in corporate ownership,
              breach of employment agreements/conditions, location change or job content change</p>                            
                        </div>

                                           
                    </div>
                </div>
            </section>
        </div>
        


        <div className="w-full flex s-center justify-center text-white cta">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between s-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                        <p className='text-2xl md:text-4xl font-bold mb-4'>Are you ready to scale your business?</p>
                        <p className="text-lg md:text-2xl">Get in touch and let us build something amazing <span className='font-black'>together!</span></p>
                    </div>
                    
                    <div className="w-full lg:w-72 pt-6 lg:mx-12">
                        <Link to="/contact" className="bg-transparent border hover:bg-blue-900 hover:border-blue-800 text-white justify-center text-center rounded-lg px-10 py-3 flex s-center group">Send a message
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        

        <div className="m-auto mt-12 max-w-6xl p-2 md:p-12 h-5/6" id='about' >

<div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
        <img alt="card img" className="rounded-t float-right" src={img2} />
    </div>
    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
        
        <h3 className="text-3xl  text-blue-900 font-bold">About Us</h3>
        <div>
            <p className='my-3 text-xl text-gray-600 font-semibold'>LION & ELEPHANTS is committed to serving the country's skill pool by finding the right local strength to meet the growing manpower demand of the next century.
</p>
        </div>
        
        <div>
            <p className='my-3 text-xl text-gray-600 font-semibold'>LION & ELEPHANTS would find the right candidates
by employing industry experts in sourcing to selection
before presenting them to the client/company/enterprise.</p>
        </div>
        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex s-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
            Talk to Us!
            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
    </div>
</div>
</div>





            <Footer />
            <FloatingMenu />
        </div>

    );
};

export default Home;

