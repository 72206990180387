import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './career.css';
const Career = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 100,
      once: true,
    });
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const [jobListings, setJobListings] = useState([
    {
      title: 'Job Title 1',
      location: 'City 1',
      description: 'Job Description: ANDROID',
      category: 'Development',
      role: 'Android Developer',
    },
    {
      title: 'Job Title 2',
      location: 'City 2',
      description: 'Job Description: WEBAPP',
      category: 'Development',
      role: 'Web Developer',
    },
    {
      title: 'Job Title 3',
      location: 'City 3',
      description: 'Job Description: HARDWARE',
      category: 'Engineering',
      role: 'Hardware Engineer',
    },
    {
      title: 'Job Title 4',
      location: 'City 4',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 5',
      location: 'City 5',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 6',
      location: 'City 6',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 7',
      location: 'City 7',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 8',
      location: 'City 8',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 9',
      location: 'City 9',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    },
    {
      title: 'Job Title 10',
      location: 'City 10',
      description: 'Job Description: SOFTWARE',
      category: 'Development',
      role: 'Software Engineer',
    }
    
  ]);

  const handleJoinUsClick = () => {
    const jobListingsElement = document.getElementById('buttons');
    if (jobListingsElement) {
      jobListingsElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    // Implement filtering based on selected options
    // Update jobListings with filtered job listings
    const filteredJobs = jobListings.filter((job) => {
      const titleMatch =
        searchTerm === '' ||
        job.title.toLowerCase().includes(searchTerm.toLowerCase());
      const categoryMatch =
        selectedCategory === '' || job.category === selectedCategory;
      const locationMatch =
        selectedLocation === '' || job.location === selectedLocation;
      const roleMatch = selectedRole === '' || job.role === selectedRole;

      return titleMatch && categoryMatch && locationMatch && roleMatch;
    });

    setJobListings(filteredJobs);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [shareableLink, setShareableLink] = useState('');

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmitResume = () => {
    if (selectedFile) {
      // Logic to send the file to the email address "clayprasanna"
      // Implement the necessary API call or backend functionality here
      console.log('Sending file to clayprasanna:', selectedFile);
    }
  };

  const handleReferToFriend = () => {
    // Logic to generate the shareable link for the job
    // You can implement your own logic for generating the link
    const jobLink = window.location.href;
    setShareableLink(jobLink);
    console.log('Shareable link:', jobLink);
  };

  return (
    <div className="career-container">
      <NavBar />
      <div className="mt-16 px-4 md:px-8 lg:px-16 py-12">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-col items-center mb-8">
            <h1 className="text-3xl font-bold">Current Openings</h1>
            <div
              onClick={handleJoinUsClick}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 cursor-pointer"
            >
              Join Us!
            </div>
          </div>
          <div className="flex justify-center items-center mt-4">
            <input
              type="text"
              placeholder="Search Jobs"
              value={searchTerm}
              onChange={handleSearch}
              className="border rounded-md px-4 py-2 w-full md:w-96"
            />
            <button
              onClick={handleSearchClick}
              className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Search
            </button>
          </div>
          <div className="mt-4 flex justify-center items-center">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="border rounded-md px-4 py-2 mx-2"
            >
              <option value="">All Categories</option>
              <option value="Development">Development</option>
              <option value="Engineering">Engineering</option>
              {/* Add other categories */}
            </select>
            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              className="border rounded-md px-4 py-2 mx-2"
            >
              <option value="">All Locations</option>
              <option value="City 1">City 1</option>
              <option value="City 2">City 2</option>
              {/* Add other locations */}
            </select>
            <select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className="border rounded-md px-4 py-2 mx-2"
            >
              <option value="">All Roles</option>
              <option value="Android Developer">Android Developer</option>
              <option value="Web Developer">Web Developer</option>
              <option value="Hardware Engineer">Hardware Engineer</option>
              <option value="Software Engineer">Software Engineer</option>
              {/* Add other job roles */}
            </select>
          </div>
          
          <div
            id="jobListings"
            className="grid gap-6 md:grid-cols-2 mt-8"
          >
            {/* Display filteredJobListings */}
            {jobListings.map((job, index) => (
              <div
                key={index}
                className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
                data-aos="fade-up"
              >
                <h2 className="text-xl font-semibold">{job.title}</h2>
                <p className="text-gray-500">Location: {job.location}</p>
                <p className="mt-2">{job.description}</p>
              </div>
            ))}
          </div>
          <br/>
          <div id="buttons" className="mt-12 flex justify-center items-center">
            <button
              onClick={handleSubmitResume}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 mx-2"
            >
              Submit Your Resume
            </button>
            <button
              onClick={handleReferToFriend}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 mx-2"
            >
              Refer a Friend
            </button>
          </div>

          {shareableLink && (
            <div className="mt-4 text-center">
              <p className="text-gray-600">
                Share this job link with your friend:
              </p>
              <a
                href={shareableLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 font-semibold underline"
              >
                {shareableLink}
              </a>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
