import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import './App.css' 

import {
  HashRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

// Import your components with correct paths
import Home from './pages/Home';
import Contact from './pages/Contact';
import Career from './pages/Career';
import Feeds from './pages/feed';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Solutions from './pages/Solutions';
import Specialization from './pages/Specialization';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("LION & ELEPHANTS - We Understand What You Need");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/feed" element={<Feeds />} />
            <Route path="/Solutions" element={<Solutions />} />
            <Route path="/employer/page1" element={<Page1 />} />
            <Route path="/employer/page2" element={<Page2 />} />
            <Route path="/employer/page3" element={<Page3 />} />
            <Route path="/specialization" element={<Specialization />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
