import React from 'react';
import img from '../images/imgp1.png';
import { Link } from 'react-router-dom';
import FloatingMenu from '../components/FloatingMenu';
import Footer from '../components/Footer';
const Page3p2 = () => {
    return (
        
        <>
        

        
        <div className="relative flex flex-col lg:flex-row space-y-16 lg:space-y-0 text-center lg:text-left container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
          <div className="lg:w-1/2 lg:flex lg:items-center">
            <div>
              
              <h1 className="text-4xl font-black text-black mb-4 dark:text-black">
              Elevating Your Business with <span className="text-blue-600  dark:text-blue-400">L & E</span>
              </h1>
              <h2 className="text-xl leading-relaxed font-medium text-gray-700 dark:text-black-50">
              LION & ELEPHANTS is your trusted partner for bespoke executive search solutions.
              </h2>
              <div className="bg-white border-l-4 shadow-sm border-deep-blue-accent-400 transition hover:border-blue-600 ">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">
                Executive Search
                </h6>
                <p className="text-sm text-gray-900">
                Our executive search service is meticulously designed to identify, approach, and secure high-caliber C-suite executives, senior management, and board-level talent.
                </p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-blue-accent-400 transition hover:border-blue-600 ">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">
                Leadership Advisory
                </h6>
                <p className="text-sm text-gray-900">
                Beyond executive search, we offer comprehensive leadership advisory services to help organizations make informed decisions about their leadership teams.
                </p>
              </div>
            </div>
            </div>
          </div>
          <div className="bg-white ml-16 transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    
                                </div>
                            </div>
            
        </div>
        
        <FloatingMenu /> 
          
        </>
    )
}

export default Page3p2;